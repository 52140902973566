@import "../../variables.scss";

.tracks-table {
    thead{
        tr {
            background-color: $spotify-green;
            color: $spotify-white;
        }
        th i {
            display: inline;
            margin-left:.5em;
        }
        th:hover{
            cursor: pointer;
        }
        
        
    }
    tr:hover{
        background-color: $spotify-green !important;
        color: $spotify-white !important;
    }
    
}