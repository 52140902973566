@import "../../variables.scss";

.navbar {
    height: 5.3em;
    background-color: $spotify-black;
    border-bottom: .5em solid $spotify-green;
    color: $spotify-green;

     @media screen and (max-width: 645px){
         height: 6em;
     }
    .navbar-brand {
        font-size: 2em;
        color: inherit;
        @media screen and (max-width: 646px){
            font-size: 1.2em;
            font-weight: 500;
        }
        @media screen and (max-width: 345px){
            font-size: 1em;
            font-weight: 0;
        }
        &:hover {
            text-decoration: none;
           
        }
    }
    
    img {
        
        border-radius: 50%;
        height:100%;
        margin-right: 1em;
        @media screen and (max-width: 690px){
            height: 70%;
            position: absolute;
            right: 2em;
            top: .2em;
        }
        @media screen and (max-width: 345px){
            height: 50%;
            top: 1em;
            right: .5em;
        }
      
    }
     
    p {
        margin-right: 0em;
        position: absolute;
        right: 8em;
        @media screen and (max-width: 690px){
            right: .2em;
            top: 4.1em;
            font-size: .9em;
        }
        @media screen and (max-width: 345px){
            font-size: .6em;
            top: 7em;
            right: .5em;
        }
    }

    a{
        color: $spotify-green;
        font-size: .9em
    }

    a:hover {
        color: $spotify-white;
        text-decoration: none;
        
    }
}