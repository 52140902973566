@import "../../variables.scss";

.login-page{
    
    .parallax {
        background-image: url("https://images.unsplash.com/photo-1509335919466-c196457ea95a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
        height: 31.25em;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
       
    }
    .jumbotron {
        background: rgba(50,50,50, 0.4);
        text-align: center;
        color: $spotify-white;
        @include centering (90%);
       position: absolute;
       top: 8em;
       left: 0;
       right: 0;
       border-radius: 1em !important;
       height: 25em;
      
    
       .display-3{
           font-weight: 900;
           @media screen and (max-width: 991px){
            font-size: 3.5em;
        }
        
        @media screen and (max-width: 500px){
            font-size: 2em;
            height: 50%;
        }
       }
       
       button {
        font-size: 1.5em;
        line-height: 1.2;
        border-radius: 500px;
        padding: 18px 56px 14px;
        background-color: $spotify-green;
        opacity: .8;
        font-weight: 900;
        @media screen and (max-width: 991px){
            font-size: 1em;
        }
        @media screen and (max-width: 415px){
            font-size: 1em;
            padding: 10px 25px 8px;
        }

        &:hover{
            opacity: 1;
        }
       }
       
    }
    h2 {
        margin-top: .5em;
        text-align: center;
        @media screen and (max-width: 500px){
            font-size: 1.5em;
        }
        
    }
    p {
        @media screen and (max-width: 500px){
            font-size: 1em;
        } 
    }
    .row {
        margin-top: 1em;
        .offset-3 {
            @media screen and (max-width:500px){
                margin-left: 10% !important;
            }
        }
        .col-6{
            @media screen and (max-width: 500px){
              flex: 0 0 100% !important;
              max-width: 80%;  
            }
        }
    }
    
    
}
.footer {
    margin-top: 1.5em;
}


// responsive 

