@import "../../variables.scss";

.glossary {
    header {
        font-size: 1.5em;
        font-weight: 900;
        margin-left: .75em;
    }
    figcaption {
        font-size: 1.3em;
        font-weight: 600;
    }
    figure {
        margin-left: 1em;
    }

}