@import "../../variables.scss";

.user-page-logged-in{
   
    .grid {
        display: grid;
        grid-template-rows: 
          repeat(1, 20em);
        grid-template-columns: 
          repeat(2, 1fr);
        grid-column-gap: 10px;
        width: 60%;
        margin-right: auto;
        margin-left:  auto;
        margin-top: 1em;
        margin-bottom: 1em;
        @media screen and (max-width: 700px){
            width: 100%;
        }

        @media screen and (max-width: 500px){
            grid-template-rows: repeat(2,20em);
            grid-template-columns: repeat(1,1fr);
            grid-row-gap: 1em;
            
        }
      }
      
    .tracks-table{
        @include centering(95%);
        margin-top: .5em;
        .song-title:hover {
            cursor: pointer;

        }
    }
    
    .stats-item{
       
        border-radius: 10%;
        header,p{

            text-align: center;
            
        }
        header {
            margin-top: 1em;
            font-weight: 600;
        }
    }
    .my-song-item{
        
        p,header{
            text-align: center;
            
        }
        header {
            margin-top: 1em;
            font-weight: 600;
        }
        img {
            display: block;
            max-width: 40%;
            
            margin-right: auto;
            margin-left: auto;
            @media screen and (min-width: 1333px){
                width: 32.5%;
                
            }
            @media screen and (min-width: 1745px){
                width: 25%;
                
            }

            @media screen and (max-width:500px) {
                width: 33%;
            }
        }
    }

    //card flip animation

    .flip-card{
        
        background-color: transparent;
        perspective:1000px;
    }
    .flip-card-inner{
        border: 2px solid $spotify-black;
        border-radius: 10%;
        position: relative;
        width:100%;
        height:100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        
    }
    .flip-card:hover .flip-card-inner{
        transform: rotateY(180deg)
    }
    //takes care of hover effect on mobile devices
    #flipped {
        transform: rotateY(180deg)
    }

    .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }

    .flip-card-front{
        z-index: 2;
    }
    .flip-card-back{
        transform: rotateY(180deg);
        z-index: 1;
           
       
    }
    //loader 
    #preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    #loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $spotify-green;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    #loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $spotify-black;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }
    #loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $spotify-green;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    @-webkit-keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

 //views header
 .views-container{
    
     header {
         width: 100%;
         
     }
      h2 {
        padding-left: .5rem;
        margin: .5rem 2.5rem;
        @media screen and (max-width: 675px){
            padding-left: .2rem;
        }
    }
   
     ul li {
        display: inline-block;
        position: relative;
        font-size: 1.5rem;
        padding: .5rem;
    
        @media screen and (max-width:675px){
            font-size: 1.1em;
            padding: .25em;

        }
        @media screen and (max-width: 345px){
            font-size: 1em;
            padding: .1em;
        }
    }
     ul li:hover:not(#view-text) {
        background: $spotify-green;
        color: $spotify-black;
        cursor: pointer;
    }
    #views-toggles {
        // border-top: 5px solid $spotify-green;
        border-bottom: 5px solid $spotify-green;
        width: 100%;
        @media screen and (max-width: 675px){
            text-align: center;
            margin-left: 0px;
            margin-inline-start: 0px !important;
        }
    }
    #view-text{
        font-weight: 500;
        
    }
    #long_term{
        background: $spotify-green;
    }

 }
 .instructions {
     text-align: center;
 }
 // victory chart 
 .VictoryContainer {
     position: absolute !important;
     top: 1.75em;
     @media screen and (max-width: 675px ) {
         top: 1.65em;
     }
 }

 .swal2-footer {
    img {
        width: 2.5em;
        height: 2.5em;
    }
    text {
        color: $spotify-green !important;

    }
 }

 .no-data-message{
     img{
     @include centering(50%)
 }
 p{
     text-align: center;
     font-size: 1.75em
 }
}
}